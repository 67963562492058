export const appHead = {"meta":[{"name":"facebook-domain-verification","content":"c2biswka4yfidwbqjlm9weq5igljyo"},{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1, maximum-scale=1"}],"link":[{"rel":"preconnect","href":"https://use.typekit.net/"},{"rel":"preconnect","href":"https://p.typekit.net/"},{"rel":"preload","as":"style","onload":"this.rel=\"stylesheet\"","href":"https://use.typekit.net/jva3fmf.css"},{"rel":"dns-prefetch","href":"https://www.googletagmanager.com"},{"rel":"preconnect","href":"https://a.storyblok.com"},{"rel":"dns-prefetch","href":"https://a.storyblok.com"},{"rel":"preconnect","href":"https://cdn-ukwest.onetrust.com"},{"rel":"dns-prefetch","href":"https://cdn-ukwest.onetrust.com"}],"style":[],"script":[{"innerHTML":"\n                      /* <![CDATA[ */\n                          var google_conversion_id = 1065425231;\n                          var google_custom_params = window.google_tag_params;\n                          var google_remarketing_only = true;\n                      /* ]]> */\n                  ","type":"text/javascript","body":true},{"src":"//www.googleadservices.com/pagead/conversion.js","type":"text/javascript","defer":true,"body":true}],"noscript":[{"innerHTML":"\n                      <div style=\"display:inline;\">\n                          <img height=\"1\" width=\"1\" style=\"border-style:none;\" alt=\"\" src=\"//googleads.g.doubleclick.net/pagead/viewthroughconversion/1065425231/?value=0&amp;guid=ON&amp;script=0\"/>\n                      </div>\n                  "}]}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const appSpaLoaderTag = "div"

export const appSpaLoaderAttrs = {"id":"__nuxt-loader"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"prefetch":false,"externalRelAttribute":"","componentName":"NuxtLink","prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false

export const chunkErrorEvent = "vite:preloadError"

export const crawlLinks = false

export const spaLoadingTemplateOutside = false

export const purgeCachedData = true